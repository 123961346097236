//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\userMenu\userMenuDirective.js
(function () {
    'use strict';

    angular
    .module('userMenuModule')
    .directive('userMenu', userMenuDirective);

    function userMenuDirective() {
        return {
            restricts: 'E',
            scope: {
                returnUrl: '=?'
            },
            controller: 'userMenuCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/userMenu/userMenu.html'
        }
    };

})();