//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\distributor\selectDistributorCtrl.js
(function () {
    'use strict';

    angular
        .module('distributorModule')
        .controller('selectDistributorCtrl', selectDistributorCtrl);

    selectDistributorCtrl.$inject = ['$scope', '$timeout', 'toaster', 'distributorCacheSvc'];

    function selectDistributorCtrl($scope, $timeout, toaster, distributorCacheSvc) {
        var vm = this;
        vm.distributors = [];
        vm.dropdownOpen = false;
        vm.singleSelect = true;
        vm.showSearch = false;
        vm.showOnlyHillyard = false;
        vm.distributorFilter = '';
        vm.defaultText = 'Please select';

        activate();

        ////////////////////////
        function activate() {
            distributorCacheSvc.getUserDistributors().then(function (data) {
                vm.showSearch = data.length > 10;
                for (var i = 0; i < data.length; i++) {
                    data[i].selected = false;
                }
                if (data.length === 1) {
                    data[0].selected = true;
                    vm.distributors = data;
                    vm.createDefaultText();
                    vm.raiseSelectedDistributorEvent();
                } else {
                    vm.distributors = data;
                }

                if (data.length === 0) {
                    toaster.pop('info', '', 'Zero Distributors returned');
                }
            });
        };

        vm.distributorSelected = function (distributor) {
            var selected = distributor.selected;
            if (vm.singleSelect === true || vm.singleSelect === 'true') {
                vm.dropdownOpen = false;
                for (var i = 0; i < vm.distributors.length; i++) {
                    vm.distributors[i].selected = false;
                }
            }
            distributor.selected = !selected;
            vm.createDefaultText();
            vm.raiseSelectedDistributorEvent();
        };

        vm.checkUnCheckAll = function (checkAll) {
            for (var i = 0; i < vm.distributors.length; i++) {
                if (!checkAll) {
                    vm.distributors[i].selected = checkAll;
                } else {
                    var selected = true;
                    if (vm.distributorFilter.length > 0) {
                        if ((vm.distributors[i].name === null || vm.distributors[i].name.toLowerCase().indexOf(vm.distributorFilter) === -1)) {
                            selected = false;
                        }
                    }
                    if (vm.showOnlyHillyard === true && vm.distributors[i].distributorType === 'I') {
                        selected = false;
                    }
                    vm.distributors[i].selected = selected;
                }
            }
            vm.createDefaultText();
            vm.raiseSelectedDistributorEvent();
        };

        vm.selectedCount = function () {
            var selected = 0;
            for (var i = 0; i < vm.distributors.length; i++) {
                selected = selected + (vm.distributors[i].selected ? 1 : 0);
            }
            return '{0} selected'.format(selected);
        };

        vm.raiseSelectedDistributorEvent = function () {
            var selectedDistributors = [];
            for (var i = 0; i < vm.distributors.length; i++) {
                if (vm.distributors[i].selected) {
                    selectedDistributors.push(vm.distributors[i]);
                }
            }
            vm.selectedDistributorsEvent()(selectedDistributors);
        };

        vm.createDefaultText = function () {
            vm.defaultText = '';
            var selectedLabels = [];
            for (var i = 0; i < vm.distributors.length; i++) {
                if (vm.distributors[i].selected) {
                    selectedLabels.push(vm.distributors[i].name);
                }
            }
            if (selectedLabels.length > 0) {
                vm.defaultText = selectedLabels.join(', ');
            } else {
                vm.defaultText = 'Please select';
            }
        };

        vm.filterDistributors = function () {
            return function (distributor) {
                if ((distributor.name != null && distributor.name.toLowerCase().indexOf(vm.distributorFilter) > -1)) {
                    if (vm.showOnlyHillyard === true && distributor.distributorType === 'I') {
                        return false;
                    }
                    return true;
                }
                return false;
            };
        };

        $scope.$on('closeDropdownEvent', function (event, args) {
            $timeout(function () {
                vm.dropdownOpen = false;
            }, 50);
        });

    };
})();