//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\systemStatus\releaseNotesFlagDirective.js
(function () {
    'use strict';

    angular
    .module('systemStatusModule')
    .directive('releaseNotesFlag', releaseNotesDirective);

    function releaseNotesDirective() {
        return {
            restricts: 'E',
            scope: {
                applicationId: '@',
                messageUrl: '@'
            },
            controller: 'releaseNotesFlagCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/systemStatus/releaseNotesFlag.html'
        }
    };

})();