//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\userMenu\userMenuCtrl.js
(function () {
    'use strict';

    angular
        .module('userMenuModule')
        .controller('userMenuCtrl', userMenuCtrl);

    userMenuCtrl.$inject = ['serverSvc'];

    function userMenuCtrl(serverSvc) {
        var vm = this;
        vm.applicationMenuVisible = false;
        vm.userMenu;
        vm.loaded = false;

        activate();

        ////////////////////////
        function activate() {
            var url = serverSvc.getSSOServer() + 'application/RetrieveUserMenu/';

            if (vm.returnUrl) {
                url = url + '?returnUrl=' + vm.returnUrl;
            }

            serverSvc.getData(url)
                .then(function (results) {
                    vm.userMenu = results;
                    vm.loaded = true;
                });
        };

    };
})();