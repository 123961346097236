//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\systemStatus\releaseNotesListCtrl.js
(function () {
    'use strict';

    angular
        .module('systemStatusModule')
        .controller('releaseNotesListCtrl', releaseNotesListCtrl);

    releaseNotesListCtrl.$inject = ['$scope', '$sce', 'serverSvc'];

    function releaseNotesListCtrl($scope, $sce, serverSvc) {
        var vm = this;
        vm.applicationId = 0;
        vm.apiUrl = '';
        vm.messages = [];
        vm.loaded = false;
        vm.loadingMessage = 'Loading...';
        activate();

        ////////////////////////
        function activate() {
            vm.apiUrl = serverSvc.getStatusServer();
        };

        function loadReleaseNotes() {
            var url = vm.apiUrl + 'api/messageapi/RetrieveReleaseNotes/?applicationId=' + vm.applicationId;
            serverSvc.getData(url)
                .then(function (results) {
                    vm.loaded = true;
                    if (results.length > 0) {
                        vm.messages = results;
                    } else {
                        vm.messages = [];
                    }
                },
                function () {
                    vm.loaded = false;
                    vm.loadingMessage = 'Error loading release notes';
                });
        };

        $scope.$watch('vm.applicationId', function (newItem, oldItem) {
            if (newItem > 0) {
                loadReleaseNotes();
            }
        });

        vm.trustHtml = function(html) {
            return $sce.trustAsHtml(html);
        };

    };
})();