//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\selectList\selectListDirective.js
(function () {
    'use strict';

    angular
        .module('selectListModule')
        .directive('selectList', selectListDirective);

    function selectListDirective() {
        return {
            restricts: 'E',
            scope: {
                dataList: '=list',
                selectedKey: '=?',
                defaultText: '@',
                keyField: '@',
                valueField: '@',
                valueChangedEvent: '&?'
            },
            controller: 'selectListCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/selectList/selectList.html'
        };
    };

})();