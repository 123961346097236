//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\systemStatus\releaseNotesFlagCtrl.js
(function () {
    'use strict';

    angular
        .module('systemStatusModule')
        .controller('releaseNotesFlagCtrl', releaseNotesFlagCtrl);

    releaseNotesFlagCtrl.$inject = ['$window', '$scope', 'serverSvc'];

    function releaseNotesFlagCtrl($window, $scope, serverSvc) {
        var vm = this;
        vm.applicationId = 0;
        vm.apiUrl = '';
        vm.url = '';
        vm.messageUrl = '';
        vm.releaseNotesCount = 0;
        vm.iconColor = 'default';
        vm.loaded = false;

        activate();

        ////////////////////////
        function activate() {
            vm.apiUrl = serverSvc.getStatusServer();
            vm.url = serverSvc.getStatusSite();
        };

        function loadMessages() {
            var url = vm.apiUrl + 'api/messageapi/RetrieveReleaseNotesCount/?applicationId=' + vm.applicationId;

            serverSvc.getData(url)
                .then(function (results) {
                    vm.loaded = true;
                    if (results) {
                        vm.releaseNotesCount = results;
                        if (results > 0) {
                            vm.iconColor = "#31708f";
                        } else {
                            vm.iconColor = 'default';
                        }
                    } 
                });
        };

        $scope.$watch('vm.applicationId', function (newItem, oldItem) {
            if (newItem > 0) {
                loadMessages();
            }
        });

        vm.viewDetails = function () {
            if (vm.messageUrl.length > 0) {
                $window.open(vm.messageUrl, '_self');
            } else {
                $window.open(vm.url + 'message/releasenotes/?applicationId=' + vm.applicationId, '_blank');
            }
        };


    };
})();