//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\userMenu\userMenuMobileDirective.js
(function () {
    'use strict';

    angular
    .module('userMenuModule')
    .directive('userMenuMobile', userMenuMobileDirective);

    function userMenuMobileDirective() {
        return {
            restricts: 'A',
            scope: {
                returnUrl: '='
            },
            controller: 'userMenuCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/userMenu/userMenuMobile.html'
        }
    };

})();