//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\distributor\distributorCacheSvc.js
(function () {
    'use strict';

    angular
        .module('distributorModule')
        .factory('distributorCacheSvc', distributorCacheSvc);

    distributorCacheSvc.$inject = ['$q', 'serverSvc'];

    function distributorCacheSvc($q, serverSvc) {
        var distributors = [];
        return {
            getUserDistributors: function () {
                var deferred = $q.defer();
                if (distributors.length === 0) {
                    var url = serverSvc.getDataWarehouseServer() + 'v1/distributors/currentUser';
                    serverSvc.getData(url).then(function (results) {
                            distributors = results;
                            deferred.resolve(distributors);
                        });
                } else {
                    deferred.resolve(distributors);
                }
                return deferred.promise;
            },

            getUserDistributorById: function (structureId) {
                var deferred = $q.defer();
                var selectedDistributor;
                this.getUserDistributors().then(function (distributors) {
                    for (var i = 0; i < distributors.length; i++) {
                        if (distributors[i].structureId === structureId) {
                            selectedDistributor = distributors[i];
                            break;
                        }
                    }
                    deferred.resolve(selectedDistributor);
                });
                return deferred.promise;
            }

        };
    };
})();