//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\systemStatus\systemStatusDirective.js
(function () {
    'use strict';

    angular
    .module('systemStatusModule')
    .directive('systemStatus', systemStatusDirective);

    function systemStatusDirective() {
        return {
            restricts: 'E',
            scope: {
                applicationId: '@',
                messageUrl: '@'
            },
            controller: 'systemStatusCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/systemStatus/systemStatus.html'
        }
    };

})();