//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\createAccount\createAccountModule.js
(function () {
    "use strict";

    angular.module('createAccountModule',
        [
            'toaster',
            'sharedModule',
            'distributorModule',
            'territoryModule',
            'selectListModule'
        ]);
})();