//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\systemStatus\systemStatusDetailCtrl.js
(function () {
    'use strict';

    angular
        .module('systemStatusModule')
        .controller('systemStatusDetailCtrl', systemStatusDetailCtrl);

    systemStatusDetailCtrl.$inject = ['$scope', '$sce', '$location', 'serverSvc'];

    function systemStatusDetailCtrl($scope, $sce, $location, serverSvc) {
        var vm = this;
        vm.applicationId = 0;
        vm.messageId = 0;
        vm.messageDetail = undefined;
        vm.apiUrl = '';
        vm.loadingMessage = 'Loading...';
        activate();

        ////////////////////////
        function activate() {
            vm.apiUrl = serverSvc.getStatusServer();
        };

        function loadDetailMessage() {
            var url = vm.apiUrl + 'api/messageapi/RetrieveMessage/?applicationId=' + vm.applicationId + '&messageId=' + vm.messageId;
            serverSvc.getData(url)
                .then(function (results) {
                    vm.messageDetail = results;
                },
                function () {
                    vm.messageDetail = undefined;
                    vm.loadingMessage = 'Error loading notes';
                });
        };

        $scope.$watch('vm.applicationId', function (newItem, oldItem) {
            if (newItem > 0) {
                var regex = /[?&]([^=#]+)=([^&#]*)/g,
                    url = $location.$$absUrl.replace('#?messageid', ''),
                    params = {},
                    match;
                while (match = regex.exec(url)) {
                    params[match[1]] = match[2];
                }
                
                if (params && params.messageId) {
                    vm.messageId = params.messageId;
                    loadDetailMessage();
                } else {
                    vm.loadingMessage = 'A message number is required';
                }
                
            }
        });

        vm.trustHtml = function (html) {
            return $sce.trustAsHtml(html);
        };

    };
})();