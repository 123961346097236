//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\territory\selectTerritoryDirective.js
(function () {
    'use strict';

    angular
    .module('territoryModule')
    .directive('selectTerritory', selectTerritoryDirective);

    function selectTerritoryDirective() {

        return {
            restricts: 'E',
            scope: {
                singleSelect: '@',
                selectedDistributors: '=',
                selectedTerritoryEvent: '&'
            },
            controller: 'selectTerritoryCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/territory/selectTerritory.html'
        }
    };

})();