//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\createAccount\createAccountCtrl.js
(function () {
    'use strict';
    angular
        .module('createAccountModule')
        .controller('createAccountCtrl', createAccountCtrl);

    createAccountCtrl.$inject = ['$scope', 'serverSvc'];

    function createAccountCtrl($scope, serverSvc) {
        var vm = this;
        vm.distributors = [];
        vm.territories = [];
        vm.accountTypes = [];
        vm.states = [];
        vm.displayTerritories = false;
        vm.accountSaved = false;
        vm.saveLabel = 'Save';
        vm.saveDisabled = false;
        vm.account = {
            accountId: 0,
            distributorStructureId: 0,
            territoryStructureId: 0,
            accountTypeId: 0,
            accountName: '',
            accountName2: '',
            address: '',
            city: '',
            state: '',
            postalCode: '',
            isTarget: false,
            addToMobileDevice: false,
            validationErrors: []
        };
        
        activate();

        function activate() {
            loadAccountTypes();
            loadStates();
        };

        function loadAccountTypes() {
            var url = serverSvc.getDataWarehouseServer() + 'v2/accounttypes';
            serverSvc.getData(url).then(function(results) {
                vm.accountTypes = results;
            });
        };

        function loadStates() {
            var url = serverSvc.getDataWarehouseServer() + 'v2/states';
            serverSvc.getData(url).then(function (results) {
                vm.states = results;
            });
        };

        function validateAccount() {
            vm.account.validationErrors = [];
            if (vm.account.distributorStructureId === 0) {
                vm.account.validationErrors.push("Distributor is required");
            } else {
                if (vm.distributors[0].distributorType === 'C' && vm.account.territoryStructureId === 0) {
                    vm.account.validationErrors.push("Territory is required");
                }
            }
            if (vm.account.accountTypeId === 0) {
                vm.account.validationErrors.push("Account Type is required");
            }
            if (vm.account.accountName.length === 0) {
                vm.account.validationErrors.push("Account Name is required");
            }
            if (vm.account.address.length === 0) {
                vm.account.validationErrors.push("Address is required");
            }
            if (vm.account.city.length === 0) {
                vm.account.validationErrors.push("City is required");
            }
            if (vm.account.state.length === 0) {
                vm.account.validationErrors.push("State is required");
            }
            if (vm.account.postalCode.length === 0) {
                vm.account.validationErrors.push("Postal Code is required");
            }
        };

        vm.distributorSelected = function (distributors) {
            vm.distributors = distributors;
            if (distributors.length > 0) {
                vm.displayTerritories = distributors[0].distributorType === 'C';
                vm.account.distributorStructureId = distributors[0].structureId;
            } else {
                vm.account.distributorStructureId = 0;
            }
        };

        vm.territorySelected = function (territories) {
            vm.territories = territories;
            if (territories.length > 0) {
                vm.account.territoryStructureId = territories[0].structureId;
            } else {
                vm.account.territoryStructureId = 0;
            }
        };

        vm.accountTypeSelected = function(accountType) {
            vm.account.accountTypeId = accountType.accountTypeId;
        };

        vm.stateSelected = function(selectedState) {
            vm.account.state = selectedState.code;
        };

        vm.save = function () {
            validateAccount();
            
            if (vm.beforeSaveEvent()) {
                vm.beforeSaveEvent()(vm.account);
            }
            if (vm.account.validationErrors.length === 0) {
                // save
                vm.saveLabel = 'Saving...';
                vm.saveDisabled = true;
                var url = serverSvc.getDataWarehouseServer() + 'v3/accounts';
                serverSvc.postData(vm.account, url).then(function (results) {
                    vm.saveLabel = 'Save';
                    vm.saveDisabled = false;
                    if (results.status === 201) {
                        vm.accountSaved = true;
                    } else {
                        $.each(results.data.modelState, function(i, fieldItem) {
                            $.each(fieldItem, function(j, errItem) {
                                vm.account.validationErrors.push(errItem);
                            });
                        });
                    }

                    vm.account.accountId = results.data;
                    if (vm.afterSaveEvent()) {
                        vm.afterSaveEvent()(vm.account);
                    }
                },
                function(err) {
                    vm.saveLabel = 'Save';
                    vm.saveDisabled = false;
                });
            }
        };


    };
})();