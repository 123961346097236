//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\shared\serverSvc.js
(function () {
    'use strict';

    angular
        .module('sharedModule')
        .factory('serverSvc', serverSvc);

    serverSvc.$inject = ['$rootScope', '$http', '$window'];

    function serverSvc($rootScope, $http, $window) {
        return {
            getStatusServer: function () {
                var url = '';
                var host = $window.location.host;
                if (host.indexOf('localhost') >= 0) {
                    url = 'http://localhost:65382/';
                } else if (host.indexOf('dev') >= 0) {
                    url = 'https://statusapi-develop.hillyard.com/';
                } else if (host.indexOf('test') >= 0 || host.indexOf('qas') >= 0) {
                    url = 'https://statusapi-develop.hillyard.com/';
                } else {
                    url = 'https://statusapi.hillyard.com/';
                }
                return url;
            },

            getStatusSite: function () {
                var url = '';
                var host = $window.location.host;
                if (host.indexOf('localhost') >= 0) {
                    url = 'http://localhost:57577/';
                } else if (host.indexOf('dev') >= 0 || host.indexOf('test') >= 0 || host.indexOf('qas') >= 0) {
                    url = 'https://statusweb-develop.hillyard.com/';
                } else {
                    url = 'https://status.hillyard.com/';
                }
                return url;
            },

            getSSOServer: function() {
                var host = $window.location.host;
                var url = '';
                if (host.indexOf('localhost') >= 0) {
                    url = 'http://localhost:61674/api/';
                } else if (host.indexOf('dev') >= 0 || host.indexOf('test') >= 0 || host.indexOf('qas') >= 0) {
                    url = 'https://ssoapi-develop.hillyard.com/api/';
                } else {
                    url = 'https://ssoapi.hillyard.com/api/';
                }
                return url;
            },

            getDataWarehouseServer: function() {
                var url = '';
                var host = $window.location.host;
                if (host.indexOf('localhost') >= 0) {
                    url = 'http://localhost:55944/api/';
                } else if (host.indexOf('dev') >= 0 || host.indexOf('test') >= 0 || host.indexOf('qas') >= 0) {
                    url = 'https://datawarehouseapi-develop.hillyard.com/api/';
                } else {
                    url = 'https://datawarehouseapi.hillyard.com/api/';
                }
                return url;
            },

            getData: function (url, loadingMessage) {
                if (loadingMessage) {
                    $rootScope.$broadcast('loading', { show: true, message: loadingMessage });
                } else {
                    $rootScope.$broadcast('loading', { show: true, message: 'Loading..' });
                }
                
                return $http.get(url, { withCredentials: true })
                    .then(function (results) {
                        $rootScope.$broadcast('loading', { show: false });
                        return results.data;
                    }, function (err) {
                        $rootScope.$broadcast('loading', { show: false });
                        return err;
                    });
            },

            postData: function(data, url, postMessage) {
                if (postMessage) {
                    $rootScope.$broadcast('loading', { show: true, message: postMessage });
                } else {
                    $rootScope.$broadcast('loading', { show: true, message: 'Saving..' });
                }
                return $http.post(url, JSON.stringify(data), { withCredentials: true })
                    .then(function (results) {
                        $rootScope.$broadcast('loading', { show: false });
                        return results;
                    }, function (err) {
                        $rootScope.$broadcast('loading', { show: false });
                        return err;
                    });
            },

            putData: function (data, url, postMessage) {
                if (postMessage) {
                    $rootScope.$broadcast('loading', { show: true, message: postMessage });
                } else {
                    $rootScope.$broadcast('loading', { show: true, message: 'Saving..' });
                }
                return $http.put(url, JSON.stringify(data), { withCredentials: true })
                    .then(function (results) {
                        $rootScope.$broadcast('loading', { show: false });
                        return results;
                    }, function (err) {
                        $rootScope.$broadcast('loading', { show: false });
                        return err;
                    });
            }

        }
    };
})();