//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\applicationMenu\applicationMenuCtrl.js
(function () {
    'use strict';

    angular
        .module('applicationMenuModule')
        .controller('appMenuCtrl', appMenuCtrl);

    appMenuCtrl.$inject = ['serverSvc'];

    function appMenuCtrl(serverSvc) {
        var vm = this;
        vm.applicationMenuVisible = false;
        vm.applications;
        vm.loaded = false;
        vm.authenticated = false;
        vm.loginLink = '';
        vm.showLogin = false;

        activate();

        ////////////////////////
        function activate() {
            if (angular.isUndefined(vm.showLoginLink)) {
                vm.showLogin = false;
            } else {
                if (vm.showLoginLink === 'True' || vm.showLoginLink === 'true') {
                    vm.showLogin = true;
                } else {
                    vm.showLogin = false;
                }
            }

            var url = serverSvc.getSSOServer() + 'application/RetrieveUserApplicationMenuLogin';
            serverSvc.getData(url)
                .then(function (results) {
                    vm.applications = results.Applications;
                    vm.authenticated = results.Authenticated;
                    vm.loginLink = results.LoginLink;
                    vm.loaded = true;
                });
        };



    };
})();