//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\territory\selectTerritoryCtrl.js
(function () {
    'use strict';

    angular
        .module('territoryModule')
        .controller('selectTerritoryCtrl', selectTerritoryCtrl);

    selectTerritoryCtrl.$inject = ['$scope', '$timeout', 'serverSvc'];

    function selectTerritoryCtrl($scope, $timeout, serverSvc) {
        var vm = this;
        vm.dropdownOpen = false;
        vm.selectedCorpDistributors = [];
        vm.distributorTerritories = [];
        vm.singleSelect = true;
        vm.defaultText = '';

        activate();

        ////////////////////////
        function activate() {
            createDefaultText();
        };

        vm.loadTerritories = function () {
            if (vm.selectedCorpDistributors.length > 0) {
                var distributorIds = [];
                for (var i = 0; i < vm.selectedCorpDistributors.length; i++) {
                    distributorIds.push(vm.selectedCorpDistributors[i].structureId);
                }
                var url = serverSvc.getDataWarehouseServer() + 'v1/territories?distributorIds=' + distributorIds.join('&distributorIds=');
                serverSvc.getData(url).then(function(results) {
                    var data = results;
                    for (var i = 0; i < data.length; i++) {
                        for (var x = 0; x < data[i].territories.length; x++) {
                            data[i].territories[x].selected = false;
                        }
                        data[i].SelectAll = false;
                        vm.distributorTerritories.push(data[i]);
                    }
                    if (vm.distributorTerritories.length === 1 && vm.distributorTerritories[0].territories.length === 1) {
                        vm.distributorTerritories[0].territories[0].selected = true;
                        vm.raiseSelectedTerritoryEvent();
                        createDefaultText();
                    }
                });
            } else {
                vm.dropdownOpen = false;
            }
        };

        vm.toggleDropdown = function () {
            vm.dropdownOpen = !vm.dropdownOpen;
            if (vm.dropdownOpen === true && vm.distributorTerritories.length === 0) {
                vm.loadTerritories();
            }
        };

        function createDefaultText() {
            vm.defaultText = '';
            if (vm.selectedCorpDistributors.length === 0) {
                vm.defaultText = 'Please select a Hillyard Distributor';
            } else {
                if (vm.distributorTerritories.length === 0) {
                    vm.defaultText = 'Please select an Account Manager';
                } else {
                    var selectedLabels = [];
                    for (var i = 0; i < vm.distributorTerritories.length; i++) {
                        for (var x = 0; x < vm.distributorTerritories[i].territories.length; x++) {
                            if (vm.distributorTerritories[i].territories[x].selected === true) {
                                selectedLabels.push("{0} ({1})"
                                    .format(vm.distributorTerritories[i].territories[x].fullName,
                                        vm.distributorTerritories[i].territories[x].name));
                            }
                        }
                    }
                    if (selectedLabels.length > 0) {
                        vm.defaultText = selectedLabels.join(', ');
                    } else {
                        vm.defaultText = 'Please select an Account Manager';
                    }
                }
            }
        };

        vm.selectAllClicked = function (distributor) {
            if (vm.singleSelect === false || vm.singleSelect == 'false') {
                distributor.SelectAll = !distributor.SelectAll;
                for (var i = 0; i < distributor.territories.length; i++) {
                    distributor.territories[i].selected = distributor.SelectAll;
                }
            }
            createDefaultText();
            vm.raiseSelectedTerritoryEvent();
        }

        vm.territorySelected = function (territory) {
            var selected = territory.selected;
            if (vm.singleSelect === true || vm.singleSelect === 'true') {
                vm.dropdownOpen = false;
                for (var i = 0; i < vm.distributorTerritories.length; i++) {
                    for (var x = 0; x < vm.distributorTerritories[i].territories.length; x++) {
                        vm.distributorTerritories[i].territories[x].selected = false;
                    }
                }
            }
            territory.selected = !selected;
            createDefaultText();
            vm.raiseSelectedTerritoryEvent();
        };

        vm.raiseSelectedTerritoryEvent = function () {
            var selectedTerritories = [];
            for (var i = 0; i < vm.distributorTerritories.length; i++) {
                for (var x = 0; x < vm.distributorTerritories[i].territories.length; x++) {
                    if (vm.distributorTerritories[i].territories[x].selected) {
                        selectedTerritories.push(vm.distributorTerritories[i].territories[x]);
                    }
                }
            }
            vm.selectedTerritoryEvent()(selectedTerritories);
        };

        $scope.$watch('vm.selectedDistributors', function (newValue, oldValue) {
            if (newValue != undefined) {
                vm.selectedCorpDistributors = [];
                if (newValue.length > 0) {
                    for (var i = 0; i < newValue.length; i++) {
                        if (newValue[i].distributorType === 'C') {
                            vm.selectedCorpDistributors.push(newValue[i]);
                        }
                    }
                }
                vm.distributorTerritories = [];
                if (vm.selectedCorpDistributors.length === 1) {
                    vm.loadTerritories();
                }
                createDefaultText();
                vm.raiseSelectedTerritoryEvent();
                vm.dropdownOpen = false;
            }
        });

        $scope.$on('closeDropdownEvent', function (event, args) {
            $timeout(function () {
                vm.dropdownOpen = false;
            }, 50);
        });

    };
})();