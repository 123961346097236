//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\systemStatus\systemStatusCtrl.js
(function () {
    'use strict';

    angular
        .module('systemStatusModule')
        .controller('systemStatusCtrl', systemStatusCtrl);

    systemStatusCtrl.$inject = ['$window', '$scope', 'serverSvc'];

    function systemStatusCtrl($window, $scope, serverSvc) {
        var vm = this;
        vm.applicationId = 0;
        vm.messages = [];
        vm.apiUrl = '';
        vm.url = '';
        vm.messageUrl = '';
        vm.loaded = false;

        activate();

        ////////////////////////
        function activate() {
            vm.apiUrl = serverSvc.getStatusServer();
            vm.url = serverSvc.getStatusSite();
        };

        function loadMessages() {
            var url = vm.apiUrl + 'api/messageapi/RetrieveActiveMessages/?applicationId=' + vm.applicationId;
            serverSvc.getData(url)
                .then(function (results) {
                    vm.loaded = results.length > 0;
                    vm.messages = results;
                });
        };

        $scope.$watch('vm.applicationId', function (newItem, oldItem) {
            if (newItem > 0) {
                loadMessages();
            }
        });

        vm.viewDetails = function (messageId) {
            if (vm.messageUrl.length > 0) {
                $window.open(vm.messageUrl + '?messageId=' + messageId, '_self');
            } else {
                $window.open(vm.url + 'message/detail/?messageId=' + messageId, '_blank');
            }
        };


    };
})();