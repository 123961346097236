//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\createAccount\createAccountDirective.js
(function () {
    'use strict';

    angular
    .module('createAccountModule')
    .directive('createAccount', createAccountDirective);

    function createAccountDirective() {

        return {
            restricts: 'E',
            scope: {
                beforeSaveEvent: '&',
                afterSaveEvent: '&'
            },
            controller: 'createAccountCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/createAccount/createAccount.html'
        }
    };

})();