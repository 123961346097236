//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\applicationMenu\applicationMenuDirective.js
(function () {
    'use strict';

    angular
    .module('applicationMenuModule')
    .directive('applicationMenu', applicationMenuDirective);

    function applicationMenuDirective() {
        return {
            restricts: 'E',
            scope: {
                showLoginLink:'@'
            },
            controller: 'appMenuCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/applicationMenu/applicationMenu.html'
        }
    };

})();