//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\selectList\selectListCtrl.js
(function () {
    'use strict';
    angular
        .module('selectListModule')
        .controller('selectListCtrl', selectListCtrl);

    selectListCtrl.$inject = ['$scope'];

    function selectListCtrl($scope) {
        var vm = this;
        vm.defaultText = 'Please select';
        vm.selectedItem = undefined;

        vm.findSelectedItem = function () {
            if (vm.selectedKey != undefined && vm.dataList != undefined) {
                for (var i = 0; i < vm.dataList.length; i++) {
                    if (vm.dataList[i][vm.keyField] == vm.selectedKey) {
                        vm.selectedItem = vm.dataList[i];
                        break;
                    }
                }
            } else {
                vm.selectedKey = undefined;
                vm.selectedItem = undefined;
            }
        };

        vm.selectItem = function (item) {
            if (vm.selectedItem != undefined && vm.selectedItem[vm.keyField] === item[vm.keyField]) {
                vm.selectedKey = undefined;
                vm.selectedItem = undefined;
            } else {
                vm.selectedKey = item[vm.keyField];
                vm.selectedItem = item;
            }
            if (vm.valueChangedEvent != undefined) {
                vm.valueChangedEvent()(item);
            }
        };

        $scope.$watch('vm.selectedKey', function (newItem, oldItem) {
            vm.findSelectedItem();
        });

    };
})();