//D:\ApplicationDevelopmentHillyardGit\Net\CDN\CDN\app\ext-modules\distributor\selectDistributorDirective.js
(function () {
    'use strict';

    angular
    .module('distributorModule')
    .directive('selectDistributor', selectDistributorDirective);

    function selectDistributorDirective() {
        return {
            restricts: 'E',
            scope: {
                singleSelect: '@',
                selectedDistributorsEvent: '&'
            },
            controller: 'selectDistributorCtrl',
            controllerAs: 'vm',
            bindToController: true,
            templateUrl: '/app/ext-modules/distributor/selectDistributor.html'
        }
    };

})();